import { withAuth } from '@/api/request.js'
import { objectToGQLParameters } from '@/utils/helpers.js'
import {
  getAccountTransaction,
} from '@/api/fragments/index.js'
import dayjs from 'dayjs'

const request = withAuth()

export const getGeneralTransactionListData = ({ accounts, page, limit, filters }) => {
  if (!filters) filters = {}
  if (!filters.startDate) filters.startDate = '1970-01-01' // CORE gives only last 31 days by default, so we need this hack for full list
  return request({
    data: {
      query: `
      {
        getAccountsHistory(
          savingsIds: ${JSON.stringify(accounts)}
          includeAuthorizedTx: true
          includeRejectedAuth: true
          where: ${objectToGQLParameters(filters)}
          page: { limit: ${limit}, offset: ${(page - 1) * limit} }
        ) {
            ...getAccountTransaction
          }
        }
        ${getAccountTransaction}
      `
    }
  })
}

export const getMinimalThisMonthTransactionListData = (accounts) => {

  const currentMonth = dayjs().date(1).format('YYYY-MM-DD')

  return request(
    {
      data: {
        query: `
          {
            getAccountsHistory(
              includeAuthorizedTx: true,
              savingsIds: ${JSON.stringify(accounts)}
              where: { startDate: "${currentMonth}" }
            ) {
              select {
                amount
                transactionSubType
                transactionType
              }
            }
          }`
      }
    }
  )
}