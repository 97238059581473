import getInitialData from '@/api/views/initialData.js'
import { getDashboardSavingsAccounts } from '@/api/views/dashboard.js'
import getAccountListData from '@/api/views/accounts/accountList.js'
import getCurrentAccountDetailsData from '@/api/views/accounts/currentAccountDetails.js'
import getLoanAccountDetailsData from '@/api/views/accounts/loanAccountDetails.js'
import getTransactionDetailsData, {
  getGeneralTransactionDetailsData
} from '@/api/views/transactions/transactionDetails.js'
import getCommunicationListData from '@/api/views/communications.js'
import EventBus from '@/utils/eventBus.js'
import getCompanyInformation from '@/api/views/organization.js'
import { cardsList as getCardsListData } from '@/api/views/cards/index.js'
import getRecipientListData from '@/api/views/recipients/recipientList.js'
import getRecipientDetailsData from '@/api/views/recipients/recipientDetails.js'
import getEmployeeListData from '@/api/views/employees/employeeList.js'
import { employeeSubsidiaryAccountsAdapter } from '@/api/adapters/employees/employeeSubsidiaryAccountsAdapter'
import getClientsListData from '@/api/views/clients/clientList.js'
import { clientEntityAdapter } from '@/api/adapters/clients/clientEntityAdapter'
import { transactionTypes } from '@/utils/dictionaries/transactionTypes.dictionary'
import { LEGAL_FORM_TYPE } from '@/utils/dictionaries/legalFormType'

const actions = {
  updateDashboard ({ commit, rootGetters }, dashboardSavingsAccounts) {
    commit('accounts/setAccountsDictionary', dashboardSavingsAccounts.data.data.getSavingsAccounts, { root: true })
    commit('accounts/updateSavings', dashboardSavingsAccounts.data.data.getSavingsAccounts, { root: true })
    if(rootGetters['accounts/listSavings'].length) commit('application/updateActiveCurrency', dashboardSavingsAccounts.data.data.getSavingsAccounts[0].currency.code, { root: true })
  },
  async initialLoading ({ commit, rootState, dispatch }) {
    const currentDate = new Date()
    const beginningLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    const username = rootState.user.username
    const results = await Promise.allSettled([
      getInitialData({ beginningLastMonth, currentDate, username }),
      dispatch('preferences/fetchAccountNumberFormat', null, { root: true }),
      dispatch('accounts/getSavingProducts', null, { root: true }),
      dispatch('views/dashboard', null, { root: true })
    ])
    const errors = results.filter(result => result.status === 'rejected')
    if(errors.length){
      return errors.forEach(error => {
        EventBus.$emit('notify-with-message', error.reason.message, { flushQueue: true, status: 'error' })
      })
    } else {
      const { value: { data: { data } } } = results[0]
      const user = {
        appUser: data.AppUsers.select[0],
        clientEntity: data.Clients.select.find(client => client.legalForm === LEGAL_FORM_TYPE.ENTITY),
        clientPerson: data.Clients.select.find(client => client.legalForm === LEGAL_FORM_TYPE.PERSONAL)
      }
      commit('user/update', user, { root: true })
    }
  },
  async dashboard ({ dispatch }) {
    const savingAccounts = await getDashboardSavingsAccounts()
    dispatch('updateDashboard', savingAccounts)
  },
  async organization ({ commit }) {
    const {
      data: {
        data: {
          Organizations: {
            select: [organization]
          }
        }
      }
    } = await getCompanyInformation()
    commit('organization/update', organization, { root: true })
  },
  async accountList ({ commit }) {
    const response = await getAccountListData()

    commit('accounts/setAccountsDictionary', response.data.data.getSavingsAccounts, { root: true })
    commit('accounts/updateSavings', response.data.data.getSavingsAccounts, { root: true })
    commit('accounts/updateLoan', response.data.data.Loans.select, { root: true })
  },
  async currentAccountDetails ({ commit }, id) {
    const response = await getCurrentAccountDetailsData(id)

    commit('accounts/updateSavingsDetails', response.data.data.SavingsAccount, { root: true })
    commit('transactions/updateTransactions', { txs: response.data.data.SavingsAccountTransactions.tx, type: 'savings' }, { root: true })
    commit('transactions/updateHoldTx', response.data.data.AuthorizeTransactions.select, { root: true })
  },
  async loanAccountDetails ({ commit }, loanId) {
    const response = await getLoanAccountDetailsData(loanId)

    commit('accounts/updateLoanDetails', response.data.data.Loan, { root: true })
    commit('transactions/updateTransactions', { txs: response.data.data.LoanTransactions.select, type: 'loan' }, { root: true })
  },
  async transactionDetails ({ commit, rootGetters }, id) {
    const { data: { data } } = await getTransactionDetailsData(id)
    const isEmployee = rootGetters['user/isEmployee']

    commit('transactions/updateTransactionDetails', isEmployee ? data.getSubsidiaryAccountTransaction : data.SavingsAccountTransaction, { root: true })
  },
  async generalTransactionDetails ({ commit }, { id, type }) {
    const { data: { data } } = await getGeneralTransactionDetailsData(id, type)
    const response = data.getAccountsHistoryDetails[transactionTypes[type]]
    response.type = type
    commit('transactions/updateGeneralTransactionDetails', response, { root: true })
  },
  async communicationList ({ commit }, { page, limit, entityId }) {
    const { data: { data } } = await getCommunicationListData({ page, limit, entityId })
    commit('communications/updateCommunications', data, { root: true })
  },
  async cardsList ({ commit }) {
    const response = await getCardsListData()
    commit('cards/setState', response.data.data.Cards.select, { root: true })
  },
  async recipientList ({ commit }, { page, limit, id }) {
    const response = await getRecipientListData({ page, limit, id })
    const latestItems = response.data.data?.latest?.select || []
    const pagedItems = response.data.data?.paged?.select || []
    const fetchedItems = latestItems.concat(pagedItems)
    const allItemIDs = response.data.data.total.select

    commit('recipients/updateRecipients', { fetchedItems, allItemIDs }, { root: true })
  },
  async recipientDetails ({ commit }, { accounts, id, identifier }) {
    const { data: { data } } = await getRecipientDetailsData({ accounts, id, identifier })
    const { Recipient: { select: [recipient] }} = data
    const { creditorAccountHistories: { select: incoming }} = data
    const { debtorAccountHistories: { select: outgoing }} = data
    const recipientDetails = { ...recipient, transactions: { outgoing, incoming }}

    commit('recipients/updateRecipientDetails', recipientDetails, { root: true })
  },
  async employeesList ({ commit }) {
    const list = employeeSubsidiaryAccountsAdapter(await getEmployeeListData())
    commit('employees/updateEmployees', { list }, { root: true })
  },
  async clientsList ({ commit }) {
    const list = clientEntityAdapter(await getClientsListData())
    commit('clients/updateClients', { list }, { root: true })
  }
}

export default {
  namespaced: true,
  actions
}
