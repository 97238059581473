import { withAuth } from '@/api/request.js'

const request = withAuth()

const createEmployee = (payload) => {
  const identifier = payload.documentTypeId && (payload.documentKey || payload.documentDescription) ? `identifier: {
     documentTypeId: ${payload.documentTypeId}
     status: Active
     ${payload.documentKey ? 'documentKey: "'+payload.documentKey.replaceAll('-', '')+'"' : ''}
     ${payload.documentDescription ? 'description: "'+payload.documentDescription+'"' : ''}
  }` : ''
  return request({
    data: {
      query: `mutation {
        createSubsidiaryAccount(
          primaryAccountId: ${payload.primaryAccountId}
          input: {
            firstname: "${payload.firstname}"
            lastname: "${payload.lastname}"
            ${identifier}
            emailAddress: "${payload.email}"
            dateOfBirth: "${payload.dateOfBirth}"
            countryId: ${payload.countryId}
            city: "${payload.city}"
            designation: "${payload.designation}"
            mobileNo: "${payload.mobileNo}"
            addressTypeId: ${payload.addressTypeId}
            ${payload.stateProvinceId ? 'stateProvinceId: ' + payload.stateProvinceId : ''}
            postalCode: "${payload.postalCode}"
            addressLine1: "${payload.addressLine1}"
            addressLine2: "${payload.addressLine2 || ''}"
            roles: ${JSON.stringify(payload.roles)}
            locale: "en"
            dateFormat: "yyyy-MM-dd"
            createAccount: false
          }
        ) {
          savingsId
          clientId
          }
      }`
    }
  })
}

const activateEmployeeAccount = (payload) => {
  return request({
    data: {
      query: `mutation {
        activateSubsidiaryAccount(
          primaryAccountId: ${payload.primaryAccountId}
          subAccountId: ${payload.subAccountId}
        ) {
          resourceId
          savingsId
          subResourceId
        }
      }`
    }
  })
}

const deactivateEmployeeAccount = (payload) => {
  return request({
    data: {
      query: `mutation {
        deactivateSubsidiaryAccount(
          primaryAccountId: ${payload.primaryAccountId}
          subAccountId: ${payload.subAccountId}
          input: {
            reason: "${payload.reason}"
          }
        ) {
          resourceId
          savingsId
          subResourceId
          }
      }`
    }
  })
}

export {
  createEmployee,
  activateEmployeeAccount,
  deactivateEmployeeAccount
}
