import { clientDetails } from '@/api/views/clients/clientDetails'
import { deactivateClient, getClientTypes } from '@/api/views/clients/createClient'
import { getClientTiers } from '@/api/views/clients/clientDetails'
import { gqlErrorHelper } from '@/utils/gqlErrorHelper'

const getDefaultState = () => {
  return {
    items: [],
    clientTypes: [],
    employeeTiers: [],
    client: null,
    newClient: false
  }
}

const state = getDefaultState()

const getters = {
  list: state => state.items
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  update (state, payload) {
    Object.assign(state, payload)
  },
  updateClients (state, { list }) {
    state.items = list
  },
  updateCardStatus(state, payload) {
    const clientCard = state.client?.cards?.find(card => Number(card.id) === Number(payload.id))
    if(clientCard) clientCard.status = payload.status
  }
}

const actions = {
  resetState ({ commit }) {
    commit('resetState')
  },
  async getClient ({ commit }, { clientId }) {
    const response = await clientDetails({ clientId })
    const client = response.data?.data?.getClientDetails || null
    commit('update', { client })
  },
  async getClientTypes({ commit }) {
    const response = await getClientTypes()
    const clientTypes = response.data?.data?.CodeValues?.select || []
    commit('update', { clientTypes })
  },
  async getEmployeeTierList ({commit}) {
    const { getEmployeeTiers: employeeTiers }= gqlErrorHelper(await getClientTiers())
    commit('update', { employeeTiers })
  },
  deactivateClient (_, { clientPersonId, reason }) {
    return deactivateClient({ clientPersonId, reason })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
