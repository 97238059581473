export const gqlErrorHelper = (promiseData, beforeExecutionHandler, errorItemHandler) => {
  if (typeof beforeExecutionHandler === 'function') beforeExecutionHandler()

  const { data: { data, errors } } = promiseData

  if (errors?.length) {
    const { message, extensions } = errors[0]
    if (extensions?.errors?.length) {

      extensions.errors.forEach(
        (error) => {
          if (typeof errorItemHandler === 'function') errorItemHandler(error)
        }
      )
      throw extensions.errors[0].defaultUserMessage
    }
    throw  message
  }

  return data
}