import { withAuth } from '@/api/request.js'
import store from '@/store/index.js'

const request = withAuth()

const validateRecipient = async (nickname, id) => {
  const recipient = await request({
    data: {
      query: `{
        Beneficiaries(where: {name: { EQ: "${nickname}"}}) {
          select {
            id
          }
        }
      }`
    }
  })
  if (recipient.data.data.Beneficiaries.select.length && recipient.data.data.Beneficiaries.select[0].id !== id) {
    throw new Error('recipient_exist')
  }
}

const createOrUpdateRecipient = (recipient, paymentType, recipientId) => {
  const isInternalRecipient = paymentType === 'INTERNAL'
  const getInternalIdentifier = `ACCOUNT:${recipient.creditor.accountNumber}`
  const getExternalIdentifier = `${paymentType}://${recipient.creditor.sortCode}/${recipient.creditor.accountNumber}`
  const identifier = isInternalRecipient ? getInternalIdentifier : getExternalIdentifier
  const agent = generateAgentInput(paymentType, recipient.creditor)

  if (recipientId) {
    return request({
      data: {
        query: `mutation {
          UpdateBeneficiary: updateBeneficiary (id: "${recipientId}" input:{
            name: "${recipient.name}"
            email: "${recipient.email}"
            mobile: "${recipient.mobile}"
            paymentType: "${paymentType}"
            creditor: {
              name: "${recipient.creditor.name}"
              identifier: "${identifier}"
              accountEntity: PERSONAL
              address: [
                "${recipient.creditor.address[0]}"
                "${recipient.creditor.address[1]}"
                "${recipient.creditor.address[2]}"
              ]
              stateOrProvince: "${recipient.creditor.stateOrProvince}"
              country: "${recipient.creditor.country}"
              city: "${recipient.creditor.city}"
              postalCode: "${recipient.creditor.postalCode}"
              ${agent}
            }
          }){
            id
          }
        }`
      }
    })
  }
  return request({
    data: {
      query: `mutation {
        CreateRecipient: createBeneficiary (input:{
          name: "${recipient.name}"
          email: "${recipient.email}"
          mobile: "${recipient.mobile}"
          paymentType: "${paymentType}"
          clientId: ${store.state.user.clientEntity?.id || store.state.user.clientPerson.id}
          creditor: {
            name: "${recipient.creditor.name}"
            identifier: "${identifier}"
            accountEntity: PERSONAL
            address: [
              "${recipient.creditor.address[0]}"
              "${recipient.creditor.address[1]}"
              "${recipient.creditor.address[2]}"
            ]
            stateOrProvince: "${recipient.creditor.stateOrProvince}"
            country: "${recipient.creditor.country}"
            city: "${recipient.creditor.city}",
            postalCode: "${recipient.creditor.postalCode}"
            ${agent}
          }
        }){
          id
        }
      }`
    }
  })
}

const generateAgentInput = (paymentType, creditor) => {
  if (paymentType !== 'SWIFT' || !creditor?.agent) return ''

  return `agent: {
         name: "${creditor.agent.name}"
         country: "${creditor.agent.country}"
         identifier: "${creditor.sortCode}"
         address: [
          "${creditor.agent.address[0]}"
          "${creditor.agent.address[1]}"
        ]
         stateOrProvince: "${creditor.agent.stateOrProvince}"
         city: "${creditor.agent.city}"
         postalCode: "${creditor.agent.postalCode}"
      }`
}

export {
  createOrUpdateRecipient,
  validateRecipient,
  generateAgentInput
}
