import { withAuth } from '@/api/request.js'
import { recipient } from '@/api/fragments/index.js'
import { getAccountTransaction } from '@/api/fragments/index.js'

const request = withAuth()
const defaultStartDate = '1970-01-01'

export default ({ accounts, id, identifier }) => {
  return request({
    data: {
      query: `
      {
        Recipient: Beneficiaries(where: { id: { EQ: ${id} } }) {
          select {
            id
            ...recipient
          }
        }
        creditorAccountHistories: getAccountsHistory(
          savingsIds: ${JSON.stringify(accounts)}
          includeAuthorizedTx: true
          includeRejectedAuth: true
          where: {
            startDate: "${defaultStartDate}"
            creditor: { identifier: "${identifier}" }
          }
        ) {
          ...getAccountTransaction
        }

        debtorAccountHistories: getAccountsHistory(
          savingsIds: ${JSON.stringify(accounts)}
          includeAuthorizedTx: true
          includeRejectedAuth: true
          where: {
            startDate: "${defaultStartDate}"
            debtor: { identifier: "${identifier}" }
          }
        ) {
          ...getAccountTransaction
        }
      }

      ${getAccountTransaction}

      ${recipient}
      `
    }
  })
}
