import store from '../../store/index.js'
import lazyLoad from '@/utils/lazyLoad.js'

const transferRoutes = [
  {
    path: '/transfers/internal',
    name: 'internalTransfer',
    component: lazyLoad('views/Transfers/InternalTransfer'),
  },
  {
    path: '/transfers/fx-pay',
    name: 'fxPayTransfer',
    component: lazyLoad('views/Transfers/FxPayTransfer'),
  },
  {
    path: '/transfers/ach',
    name: 'achTransfer',
    component: lazyLoad('views/Transfers/AchTransfer'),
  },
  {
    path: '/transfers/wire',
    name: 'wireTransfer',
    component: lazyLoad('views/Transfers/WireTransfer'),
  },
  {
    path: '/transfers/swift',
    name: 'swiftTransfer',
    component: lazyLoad('views/Transfers/SwiftTransfer'),
  },
  {
    path: '/transfers/allocate',
    name: 'allocateTransfer',
    component: lazyLoad('views/Transfers/AllocateTransfer'),
  },
  {
    path: '/select-transfer',
    name: 'selectTransfer',
    component: lazyLoad('views/Transfers/SelectTransfer')
  },
  {
    path: '/select-external-transfer',
    name: 'selectExternalTransfer',
    component: lazyLoad('views/Transfers/SelectExternalTransfer')
  }
]

const recipientRoutes = [
  {
    path: '/recipients/modify/internal',
    name: 'internalRecipient',
    component: lazyLoad('views/Recipients/InternalRecipient')
  },
  {
    path: '/recipients/modify/ach',
    name: 'achRecipient',
    component: lazyLoad('views/Recipients/AchRecipient')
  },
  {
    path: '/recipients/modify/wire',
    name: 'wireRecipient',
    component: lazyLoad('views/Recipients/WireRecipient')
  },
  {
    path: '/recipients/modify/swift',
    name: 'swiftRecipient',
    component: lazyLoad('views/Recipients/SwiftRecipient')
  },
  {
    path: '/select-recipient-transfer-type',
    name: 'selectRecipientTransferType',
    component: lazyLoad('views/Recipients/SelectRecipientTransferType')
  }
]

const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: lazyLoad('views/Dashboard')
  },
  {
    path: '/accounts',
    name: 'accounts',
    component: lazyLoad('views/Accounts/List')
  },
  {
    path: '/accounts/create',
    name: 'accountCreate',
    component: lazyLoad('views/Accounts/Create'),
    meta: { permissions: [{ code: 'CREATE_SAVINGSACCOUNT', full: true }] }
  },
  {
    path: '/accounts/loan/:id',
    name: 'loanAccount',
    component: lazyLoad('views/Accounts/Loan')
  },
  {
    path: '/accounts/current/:id',
    name: 'currentAccount',
    component: lazyLoad('views/Accounts/Current')
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: lazyLoad('views/Transactions')
  },
  {
    path: '/communications',
    name: 'communications',
    component: lazyLoad('views/Communications')
  },
  {
    path: '/support',
    name: 'support',
    component: lazyLoad('views/Support'),
    beforeEnter: (_to, from, next) => {
      if (store.state.support.tickets.length) return next()
      return next(from.path)
    }
  },
  {
    path: '/cards',
    name: 'cards',
    component: lazyLoad('views/Cards')
  },
  {
    path: '/settings',
    name: 'settings',
    component: lazyLoad('views/Settings')
  },
  {
    path: '/recipients',
    name: 'recipients',
    meta: { permissions: ['READ_BENEFICIARY'] },
    component: lazyLoad('views/Recipients/Recipients'),
  },
  {
    path: '/recipients/:recipientId',
    name: 'recipientDetails',
    meta: { permissions: ['READ_BENEFICIARY'] },
    component: lazyLoad('views/Recipients/RecipientDetails'),
  },
  {
    path: '/users',
    name: 'users',
    component: lazyLoad('views/Users'),
    meta: { permissions: [{ code: 'READ_APPUSER', full: true }, { code: 'READ_SUBACCOUNT', full: true }] }
  },
  {
    path: '/clients',
    name: 'clients',
    component: lazyLoad('views/Clients/Clients'),
    meta: { permissions: [{ code: 'READ_APPUSER', full: true }, { code: 'READ_SUBACCOUNT', full: true }] }
  },
  {
    path: '/clients/create/:clientId?/:step?',
    name: 'clientCreate',
    component: lazyLoad('views/Clients/ClientCreate'),
    meta: { permissions: [{ code: 'READ_APPUSER', full: true }, { code: 'READ_SUBACCOUNT', full: true }] }
  },
  {
    path: '/clients/upload/bulk',
    name: 'clientBulkUpload',
    component: lazyLoad('views/Clients/ClientBulkUpload'),
    meta: { permissions: [{ code: 'READ_APPUSER', full: true }, { code: 'READ_SUBACCOUNT', full: true }] }
  },
  {
    path: '/clients/:clientId',
    name: 'clientDetails',
    component: lazyLoad('views/Clients/ClientDetails'),
    meta: { permissions: [{ code: 'READ_APPUSER', full: true }, { code: 'READ_SUBACCOUNT', full: true }] }
  },
  ...transferRoutes.map(
    (transferRoute) => ({
      ...transferRoute,
      meta: { permissions: ['CREATE_TRANSFER', 'EXECUTE_TRANSFER'] },
    })
  ),
  ...recipientRoutes.map(
    (recipientRoutes) => ({
      ...recipientRoutes,
      meta: { permissions: ['READ_BENEFICIARY'] },
    })
  )
]

export default routes.map(route => {
  const meta = {
    ...route.meta,
    public: false
  }
  return { ...route, meta }
})
