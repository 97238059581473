import { withAuth, noAuth } from '@/api/request.js'
import { authenticationDetail } from '@/api/fragments/user.js'

const request = withAuth()
const noTokenRequest = noAuth()

const getUserDetails = () => {
  return request({
    data: {
      query: `
        {
          getAuthorizationDetail {
            otpProtectedOperations
          }
          getAuthenticationDetail {
            ...authenticationDetail
          }
          PasswordValidationPolicies (where:{active:{EQ:true}}) {
            select{
              regex
              description
            }
          }
        }
        ${authenticationDetail}
      `
    }
  })
}

const getFeatureConfiguration = () => {
  return request({
    data: {
      query: `
        {
          featureConfiguration {
            isDelinkUserFromEntity
          }
        }
      `
    }
  })
}

const getClientDetails = () => {
  return request({
    data: {
      query: `
        {
          Clients {
            select {
              id
              status
              legalForm
            }
          }
        }
      `
    }
  })
}

const getAuthenticationDetail = () => {
  return request({
    method: 'post',
    baseURL: `${process.env.VUE_APP_API_URL}/graphql`,
    data: {
      query: `
        {
          getAuthenticationDetail {
            ...authenticationDetail
          }
        }
        ${authenticationDetail}
      `
    }
  })
}

const requestResetPassword = (identifier) => {
  return noTokenRequest({
    data: {
      query: `
        mutation {
          requestPasswordResetToken(input: {identifier: "${identifier}"})
        }
      `
    }
  })
}

const resetPassword = (resetPassword) => {
  return noTokenRequest({
    data: {
      query: `
        mutation ($newPassword: String!) {
          resetPassword(input: {token: "${resetPassword.token}", password: $newPassword})
        }
      `,
      variables: {
        newPassword: resetPassword.newPassword
      }
    }
  })
}

const resetPasswordWithConfiguration = () => {
  return request({
    data: {
      query: `
        mutation {
          requestPasswordResetTokenFromPlatform
        }
      `
    }
  })
}

const changePassword = (password) => {
  return request({
    data: {
      query: `
        mutation($newPassword: String!, $currentPassword: String!) {
          changePassword (
            identifier: "${password.identifier}"
            currentPassword: $currentPassword
            newPassword: $newPassword
          )
        }
      `,
      variables: {
        newPassword: password.new,
        currentPassword: password.current
      }
    }
  })
}

const acceptTermsConditons = () => {
  return request({
    data: {
      query: `
        mutation {
          acceptTermsAndConditions
        }
     `
    }
  })
}

const getPasswordPolicy = () => {
  return noTokenRequest({
    data: {
      query: `
      {
        PasswordValidationPolicies (where:{active:{EQ:true}}) {
          select{
            regex
            description
          }
        }
      }
      `
    }
  })
}

export {
  getUserDetails,
  getAuthenticationDetail,
  requestResetPassword,
  resetPassword,
  changePassword,
  acceptTermsConditons,
  getClientDetails,
  getPasswordPolicy,
  getFeatureConfiguration,
  resetPasswordWithConfiguration
}
