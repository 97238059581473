const clientTranslation = {
  qorbis: {
    stopRegistrationModal: {
      title: 'Thank you for showing your interest in Qorbis',
      subtitleOne: 'Your business is not yet registered?',
      textOne: 'If you have not registered your business yet, we require your business to register before continuing.',
      subtitleTwo: '',
      textTwo: ''
    },
    views: {
      login: {
        welcomeMessage: 'Welcome back',
        welcomeMessageSubTitle: 'The smartest way to bank',
      }
    },
    clientCreationNotes: `IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING AN ACCOUNT: To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens a Bank Account. What this means for you: When you open an Account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see a copy of your driver's license or other identifying documents.`,
    components: {
      registration: {
        statics: {
          submitFinalConfirmation: {
            description: 'You are about to open a business account at Qorbis. Please click the button below to submit your application.',
            description2: 'Do you have any questions? <br/>Please contact us through - <a href="https://www.qorbis.com/contact-us/" target="_blank">contact us</a>'
          }
        }
      }
    }
  }
}

export default clientTranslation
