import employeeDetails from '@/api/views/employees/employeeDetails'
import employeeTransactions from '@/api/views/employees/employeeTransactions'
import { getCardProducts, getEmployeeCards, orderEmployeeCard } from '@/api/views/employees/employeeCard'
import { deactivateEmployeeAccount, activateEmployeeAccount } from '@/api/views/employees/createEmployee'
import { allocateToSubsidiaryAccounts, deallocateToSubsidiaryAccounts } from '@/api/views/transfers'

const getDefaultState = () => {
  return {
    items: [],
    employee: null,
    transactions: { select: [], total: 0 },
    cardProducts: [],
    cards: []
  }
}

const state = getDefaultState()

const getters = {
  list: state => state.items
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  update (state, payload) {
    Object.assign(state, payload)
  },
  updateEmployees (state, { list }) {
    state.items = list.map(item => {
      item.displayName = `${item.client?.firstName} ${item.client?.lastName}`
      return item
    })
  }
}

const actions = {
  resetState ({ commit }) {
    commit('resetState')
  },
  async getEmployee ({ commit }, { employeeId }) {
    const response = await employeeDetails({ employeeId })
    const employee = response.data?.data?.getSubsidiaryAccount || null
    commit('update', { employee })
  },
  async getEmployeeTransactions ({ state, commit }, { limit, offset }) {
    const response = await employeeTransactions({
      employeeId: state.employee.id,
      limit,
      offset
    })
    const transactions = response.data?.data?.getSubsidiaryAccountTransactions || { total: 0, select: [] }
    commit('update', { transactions })
  },
  async getCardProducts ({ state, commit }) {
    if (state.cardProducts.length) return
    const response = await getCardProducts()
    const cardProducts = response.data?.data?.CardProducts?.select || []
    commit('update', { cardProducts })
  },
  async getEmployeeCards ({ state, commit }) {
    const response = await getEmployeeCards({
      subAccountId: state.employee.id
    })
    const cards = response.data?.data?.getSubsidiaryAccountCards || []
    commit('update', { cards })
  },
  orderEmployeeCard (_, { productId, savingsAccountId, userId }) {
    return orderEmployeeCard({ productId, savingsAccountId, userId })
  },
  async fundEmployeeAccount ({ dispatch }, { primaryAccountId, subAccountId, amount, reference }) {
    const response = await allocateToSubsidiaryAccounts({
      debtor: { id: primaryAccountId },
      amount: [
        { id: subAccountId, amount,  }
      ], reference
    })

    dispatch('accounts/refreshAccountList')

    return response
  },
  async recallFundEmployeeAccount ({ dispatch }, { primaryAccountId, subAccountId, amount, reference }) {
    const response = await deallocateToSubsidiaryAccounts({
      debtor: { id: primaryAccountId },
      amount: [
        { id: subAccountId, amount,  }
      ], reference
    })

    dispatch('accounts/refreshAccountList')

    return response
  },
  activateEmployeeAccount (_, { primaryAccountId, subAccountId }) {
    return activateEmployeeAccount({ primaryAccountId, subAccountId })
  },
  deactivateEmployeeAccount (_, { primaryAccountId, subAccountId, reason }) {
    return deactivateEmployeeAccount({ primaryAccountId, subAccountId, reason })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
