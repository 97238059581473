export const allocateOrDeallocateToSubsidiariesAdapter = (payload) => {
  let input = '['
  payload.amount.forEach(item => {
    input += `
      {
	    subAccountId: ${item.id}
        amount: ${item.amount}
        reference: "${payload.reference}"
      }
    `
  })
  input += ']'
  return `primaryAccountId: ${payload.debtor.id}
          input: ${input}`
}
