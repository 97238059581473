import { withAuth } from '@/api/request.js'
import { recipient } from '@/api/fragments/index.js'

const request = withAuth()

export default (keyword) => {
  return request({
    data: {
      query: `
        {
          creditor: Beneficiaries
          (
            where : {
              OR:
                [
                  { creditor: { name: { LIKE_: "${keyword}"}}}
                  { creditor: { identifier: { LIKE_: "${keyword}"}}}
                ]
            }
          )

          {
            select {
              id
              name
              email
              creditor {
                name
                identifier
              }
            }
          }
          name: Beneficiaries (where: {
            name: { LIKE_ : "${keyword}"}
          }) {
            select {
              id
              ...recipient
            }
          }
        }
        ${recipient}
      `
    }
  })
}
