import { withAuth, restApi } from '@/api/request.js'
import { generateAgentInput } from '@/api/views/recipients/createRecipient'
import {allocateOrDeallocateToSubsidiariesAdapter} from '@/api/adapters/transfers/allocateOrDeallocateToSubsidiariesAdapter'

const request = withAuth()
const requestRestApi = restApi()

const getTransfers = ({ limit } = {}) => {
  return request({
    data: {
      query: `
        {
          Transfers(
            ${limit ? `page:{start: 1, limit: ${limit}}` : ''}
            where: {
              status: {EQ: EXECUTION_SUCCESS}
              creditor: {
                name: {NOT_NULL: true}
                identifier: {NOT_NULL: true}
              }

            }
          ) {
            select {
              creditor {
                name
                identifier
              }
              id(orderBy:DESC)
              amount
              valueDate
              paymentType {
                name
              }
            }
          }
        }
      `
    }
  })
}

const createUSBasedDomesticTransfer = (payload, paymentType) => {

  const debtorIdentifier = `ACCOUNT:${payload.debtor.accountNumber}`
  const creditorIdentifier =  `${paymentType}://${payload.creditor.sortCode}/${payload.creditor.accountNumber}`

  return request({
    data: {
      query: `
        mutation {
          createTransfer(input: {
            type: "CREDIT"
            paymentType: "${paymentType}"
            currency: "${payload.currency}"
            amount: ${payload.amount}
            dateFormat: "YYYY-MM-DD"
            reference: "${payload.reference}"
            debtor: {
              identifier: "${debtorIdentifier}"
            }
            creditor: {
              identifier: "${creditorIdentifier}"
              name: "${payload.creditor.name}"
              address: ${JSON.stringify(payload.creditor.address)}
              country: "${payload.creditor.country}"
              city: "${payload.creditor.city}"
              postalCode: "${payload.creditor.postalCode}"
              stateOrProvince: "${payload.creditor.stateOrProvince}"
            }
          }
          ) {id}
        }
      `
    }
  })
}

const createInternalTransfer = (payload) => {
  const debtorIdentifier = `ACCOUNT:${payload.debtor.accountNumber}`
  const creditorIdentifier = `ACCOUNT:${payload.creditor.accountNumber}`

  return request({
    data: {
      query: `
        mutation {
          createTransfer(input: {
            type: "CREDIT"
            paymentType:"INTERNAL"
            currency: "${payload.currency}"
            amount: ${payload.amount}
            debtor: {
              identifier: "${debtorIdentifier}"
            }
            creditor: {
              identifier: "${creditorIdentifier}"
            }
            reference:"${payload.reference}"
          }) {
            id
          }
        }
      `
    }
  })
}

const createSwiftTransfer = (payload) => {
  const debtorIdentifier = `ACCOUNT:${payload.debtor.accountNumber}`
  const creditorIdentifier =  `SWIFT://${payload.creditor.sortCode}/${payload.creditor.accountNumber}`

  return request({
    data: {
      query: `
        mutation {
          createTransfer(input: {
            type: "CREDIT"
            paymentType: "SWIFT"
            currency: "${payload.currency}"
            amount: ${payload.amount}
            dateFormat: "YYYY-MM-DD"
            reference: "${payload.reference}"
            debtor: {
              identifier: "${debtorIdentifier}"
            }
            creditor: {
              identifier: "${creditorIdentifier}"
              name: "${payload.creditor.name}"
              address: ${JSON.stringify(payload.creditor.address)}
              country: "${payload.creditor.country}"
              city: "${payload.creditor.city}"
              postalCode: "${payload.creditor.postalCode}"
              stateOrProvince: "${payload.creditor.stateOrProvince}"
              agent: {
                name: "${payload.creditor.agent.name}"
                country: "${payload.creditor.agent.country}"
                identifier: "${creditorIdentifier}"
                address: ${JSON.stringify(payload.creditor.agent.address)}
                stateOrProvince: "${payload.creditor.agent.stateOrProvince}"
                city: "${payload.creditor.agent.city}"
                postalCode: "${payload.creditor.agent.postalCode}"
             }
            }
          }
          ) {id}
        }
      `
    }
  })
}

const createAndSubmitFxPayTransfer = (payload) => {
  const routingNumber = payload.creditor.sortCode ? `${payload.creditor.sortCode}/` : ''
  const bankCode = payload.creditor.bankCode ? `${payload.creditor.bankCode}/` : ''
  const accountNumber = payload.creditor.accountNumber
  const creditorIdentifier =  `FXPAY://${routingNumber}${bankCode}${accountNumber}`
  const debtorIdentifier = `ACCOUNT:${payload.debtor.accountNumber}`
  let paymentRailMetaDataFields = `
    destinationCurrency: "${payload.destinationCurrency}"
    destinationAmount: ${payload.destinationAmount}
    fxHoldId: "${payload.fxItem.fxHoldId}"
    fxRate: ${payload.fxItem.fxRate}
    holdExpiryAt: "${payload.fxItem.holdExpiryAt}"
  `;
  let creditorDataFields = `
    identifier: "${creditorIdentifier}"
    name: "${payload.creditor.name}"
    accountEntity: ${payload.creditor.accountType}
    city: "${payload.creditor.city}"
    stateOrProvince: "${payload.creditor.stateOrProvince}"
    postalCode: "${payload.creditor.postalCode}"
    country: "${payload.creditor.country}"
    address: ${JSON.stringify(payload.creditor.address)}
  `
  if (payload.purposeCode) paymentRailMetaDataFields += `purposeCode: "${payload.purposeCode}"`;
  if (payload.creditor.bankAccountType) creditorDataFields += `accountType: ${payload.creditor.bankAccountType}`;

  return request({
    data: {
      query: `
        mutation {
          createAndSubmitTransfer(input: {
            type: "CREDIT",
            paymentType: "FXPAY",
            correlationId: "${payload.correlationId}",
            amount: ${payload.amount},
            paymentRailMetaData: {
              ${paymentRailMetaDataFields}
            }
            debtor: {
              identifier: "${debtorIdentifier}"
            }
            creditor: {
              ${creditorDataFields}
            }
          }
          ) {id}
        }
      `
    }
  })
}

const submitTransfer = (transferId) => {
  return request(
    {
      data: {
        query: `mutation {
          submitTransfer(id: ${transferId}) {
            id
          }
        }`
      }
    }
  )
}

const allocateToSubsidiaryAccounts = (payload) => {
  return request(
    {
      data: {
        query: `mutation {
          allocateFundsToSubsidiaryAccounts(
            ${allocateOrDeallocateToSubsidiariesAdapter(payload)}
          ) {
            primaryAccountId
            subAccountIds
          }
        }`
      }
    }
  )
}

const deallocateToSubsidiaryAccounts = (payload) => {
  return request(
    {
      data: {
        query: `mutation {
          deallocateFundsFromSubsidiaryAccounts(
            ${allocateOrDeallocateToSubsidiariesAdapter(payload)}
          ) {
            primaryAccountId
            subAccountIds
          }
        }`
      }
    }
  )
}

const deleteTransfer = (transferId) => {
  return request(
    {
      data: {
        query: `mutation {
          deleteTransfer(id: ${transferId}) {
            id
          }
        }`
      }
    }
  )
}

const draft = (payload) => {
  const {
    creditor,
    debtor,
    currency,
    amount,
    subject,
    fullName,
    paymentType
  } = payload
  let creditorIdentifier

  if (paymentType.value === 'INTERNAL') {
    creditorIdentifier = `ACCOUNT:${creditor.accountNo}`
  } else {
    creditorIdentifier = `${paymentType.value}://${creditor.sortCode}/${creditor.accountNo}`
  }
  const agent = generateAgentInput(paymentType.value, creditor)
  return request({
    data: {
      query: `
        mutation {
          createAndSubmitTransfer(input: {
            type: "CREDIT"
            paymentType: "${paymentType.value}"
            currency: "${currency}"
            amount: ${+amount}
            dateFormat: "YYYY-MM-DD"
            reference: ${JSON.stringify(subject)}
            debtor: {
              identifier: "ACCOUNT:${debtor.accountNumber}"
            }
            creditor: {
              ${creditor?.accountType ? `accountType: ${creditor.accountType}` : ''}
              identifier: "${creditorIdentifier}"
              name: "${fullName}"
              ${creditor?.address ? `address: ${JSON.stringify(creditor.address)}` : ''}
              ${creditor?.country ? `country: "${creditor.country}"` : ''}
              ${creditor?.city ? `city: "${creditor.city}"` : ''}
              ${creditor?.postalCode ? `postalCode: "${creditor.postalCode}"` : ''}
              ${creditor?.state ? `stateOrProvince: "${creditor.state}"` : ''}
              ${agent}
            }
          }
          ) {id}
        }
      `
    }
  })
}

const uploadTransferDocument = ({ transferId, formData }) => {
  const data = new FormData()
  data.append('name', formData.name)
  data.append('file', formData.file)

  return requestRestApi({
    method: 'post',
    url: `/self/transfer/${transferId}/documents`,
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 32000,
    data
  })
}

const downloadTransferDocument = (transferId, documentId) => {
  return requestRestApi({
    method: 'get',
    url: `/self/transfer/${transferId}/documents/${documentId}/attachment`,
    responseType: 'blob',
  })
}

const uploadMultipleTransferDocuments = (transferId, transferFiles) => {
  const formattedTransferFiles = transferFiles.filter((file) => !!file.file)
  const transferFilesSubmissions = formattedTransferFiles.map(
    ({ file }) => {
      const transferData = {
        file,
        name: file.name,
      }
      return (
        uploadTransferDocument({transferId, formData: transferData})
      )
    }
  )
  return Promise.all(transferFilesSubmissions)
}


export {
  draft,
  getTransfers,
  uploadTransferDocument,
  downloadTransferDocument,
  createInternalTransfer,
  createUSBasedDomesticTransfer,
  createSwiftTransfer,
  createAndSubmitFxPayTransfer,
  allocateToSubsidiaryAccounts,
  submitTransfer,
  deleteTransfer,
  uploadMultipleTransferDocuments,
  deallocateToSubsidiaryAccounts
}
