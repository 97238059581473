export const customVuetifyIcons = {
  dashboard: { component: () => import('@/svg/DashboardSvg.vue') },
  accounts: { component: () => import('@/svg/AccountsSvg.vue') },
  manageUsers: { component: () => import('@/svg/UsersSvg.vue') },
  add_circle: { component: () => import('@/svg/AddCircleSvg.vue') },
  card: { component: () => import('@/svg/CardSvg.vue') },
  recipients: { component: () => import('@/svg/RecipientsSvg.vue') },
  filter: { component: () => import('@/svg/FilterSvg.vue') },
  fund: { component: () => import('@/svg/FundSvg.vue') },
  income: { component: () => import('@/svg/IncomeSvg.vue') },
  magnifier: { component: () => import('@/svg/MagnifierSvg.vue') },
  user_add: { component: () => import('@/svg/UserAddSvg.vue') },
  edit_underline: { component: () => import('@/svg/EditUnderlineSvg.vue')},
  close: { component: () => import('@/svg/CloseSvg.vue')},
  check: { component: () => import('@/svg/CheckSvg.vue')},
  groups: { component: () => import('@/svg/GroupsSvg.vue') },
  copy: { component: () => import('@/svg/CopySvg.vue') },
  account_balance: { component: () => import('@/svg/AccountBalanceSvg.vue') },
  download: { component: () => import('@/svg/DownloadSvg.vue') },
  list: { component: () => import('@/svg/ListSvg.vue') },
  cloud: { component: () => import('@/svg/CloudSvg.vue') },
  eye: { component: () => import('@/svg/EyeSvg.vue') },
  snowflake: { component: () => import('@/svg/SnowflakeSvg.vue') },
  trash: { component: () => import('@/svg/TrashSvg.vue') },
  bank_building: { component: () => import('@/svg/BankBuildingSvg.vue') },
  q_style: { component: () => import('@/svg/QStyleSvg.vue') },
  money_coin: { component: () => import('@/svg/MoneyCoin.vue')},
  super_visor_account: { component: () => import('@/svg/SuperVisorAccountSvg.vue') },
  label_outline: { component: () => import('@/svg/LabelOutlineSvg.vue') }
}
