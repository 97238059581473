import searchTransactions from '@/api/views/transactions/transactionSearch.js'
import { sortByDate } from '@/utils/helpers'
import { getGeneralTransactionListData } from '@/api/views/transactions/transactionList'
import dayjs from 'dayjs'

const getDefaultState = () => {
  return {
    savings: [],
    loan: [],
    holdTx: [],
    cachedTransactions: [],
    cachedRequests: {}
  }
}

const state = getDefaultState()

const getters = {
  list: state => (accountIds, type) => {
    const txs = state[type].filter(tx => accountIds.includes(tx[type === 'savings' ? 'account' : 'loan'].id))

    if (type === 'savings') {
      const tmpHoldTx = state.holdTx
        .filter(tx => accountIds.includes(tx.account.id))
        .map(tx => ({ ...tx, keyId: `holdTx-${tx.id}` }))

      const tmpTxs = txs.map(tx => ({ ...tx, keyId: `saving-${tx.id}` }))

      return [...tmpHoldTx, ...tmpTxs].sort((a, b) => {
        if (new Date(a.createdAt) > new Date(b.createdAt)) return -1
        if (new Date(a.createdAt) < new Date(b.createdAt)) return 1
        return 0
      })
    }

    return txs
  },
  cachedTransactionsForAccounts: state => (accountIds) => {
    return state.cachedTransactions.filter(tx => accountIds.includes(tx.account.id))
  },
  getCachedTransaction: state => (type, id) => {
    return state.cachedTransactions.find(tx => tx.id === id && tx.type === type)
  }
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  updateTransactions (state, { txs, type }) {
    state[type] = state[type].concat(txs)
    state[type] = state[type].filter((elem, pos, savings) => savings.findIndex(tx => tx.id === elem.id) === pos)
    state[type] = state[type].sort((a, b) => {
      if (new Date(a.createdAt) > new Date(b.createdAt)) return -1
      if (new Date(a.createdAt) < new Date(b.createdAt)) return 1
      return 0
    })
  },
  addTransactionsToCache(state, payload) {
    const keys = []
    const items = payload.items?.select || []
    const total = payload.items?.total || 0
    items.forEach((transaction) => {
      transaction.key = `${transaction.type}.${transaction.id}`
      keys.push(transaction.key)
      const exists = state.cachedTransactions.some(cachedTransaction => cachedTransaction.key === transaction.key)
      if(!exists) state.cachedTransactions.push(transaction)
    })
    state.cachedTransactions.sort(sortByDate)
    if(payload.requestParameters) {
      const cachedAt = new Date()
      state.cachedRequests[JSON.stringify(payload.requestParameters)] = { keys, total, cachedAt }
    }
  },
  updateTransactionDetails (state, payload) {
    const transactionIndex = state.savings.findIndex(tx => tx.id === payload.id)
    state.savings.splice(transactionIndex, 1, payload)
  },
  updateGeneralTransactionDetails (state, payload) {
    const transactionIndex = state.cachedTransactions.findIndex(tx => tx.id === payload.id && tx.type === payload.type)
    if(transactionIndex !== -1) {
      if(payload.paymentDetailData) state.cachedTransactions[transactionIndex].paymentDetail = payload.paymentDetailData
      state.cachedTransactions[transactionIndex].hasDetails = true
    }
  },
  updateHoldTx (state, txs) {
    state.holdTx = txs
  }
}

const actions = {
  resetState ({ commit }) {
    commit('resetState')
  },
  async getTransactionsFromCacheOrAPI ({ state }, { forceUpdate, requestParameters }) {
    const requestKey = JSON.stringify(requestParameters)
    if(state.cachedRequests[requestKey] && !forceUpdate) {
      const cachedResponse = state.cachedRequests[requestKey]
      const secondsSinceCached = dayjs().diff(dayjs(cachedResponse.cachedAt)) / 1000
      if(secondsSinceCached < (5 * 60)) {
        return {
          select: state.cachedTransactions.filter(transaction => cachedResponse.keys.includes(transaction.key)),
          total: cachedResponse.total,
          cached: true
        }
      }
    }
    const { data: { data } } = await getGeneralTransactionListData(requestParameters)
    return data.getAccountsHistory
  },
  async search (context, payload) {
    const { accounts, filters } = payload

    const response = (await searchTransactions({
      accounts,
      filters
    })).data.data

    return response.result.tx
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
