import { downloadAccountReport } from '@/api/views/accounts/downloadAccountReport'
import { saveDataAsFile } from '@/utils/helpers'
import { getSavingProducts, updateAccountNickname } from '@/api/views/accounts/accountProducts.js'
import { ACCOUNT_STATUS } from '@/utils/dictionaries/accountStatuses'
import { gqlErrorHelper } from '@/utils/gqlErrorHelper'
import { createClientAccount } from '@/api/views/clients/clientAccount'
import getAccountListData from '@/api/views/accounts/accountList.js'


const getDefaultState = () => {
  return {
    savingsAccounts: [],
    loanAccounts: [],
    savingProducts: [],
    accountsDictionary: [],
    savingAccountAssignedClients: {}
  }
}

const state = getDefaultState()

const getters = {
  list: (state) => type => {
    // loan || savings || share
    return state[`${type}Accounts`]
  },
  savingsAccounts: (state) => state.savingsAccounts,
  listPrimaryAccounts: (_, getters) => {
    return getters.list('savings').filter(account => account.status === ACCOUNT_STATUS.ACTIVE && account.accountSubType === 'PRIMARY_ACCOUNT')
  },
  listAllSubsidiaryAccounts: (state) => {
    return state.accountsDictionary.filter(account => account.status === ACCOUNT_STATUS.ACTIVE && account.accountSubType === 'SUBSIDIARY_ACCOUNT')
  },
  listSavings: (_, getters) => {
    return getters.list('savings').filter(account => account.status === ACCOUNT_STATUS.ACTIVE)
  },
  activeSavings: (_, getters) => {
    return getters.list('savings').filter(account => account.status === ACCOUNT_STATUS.ACTIVE && account.subStatus === 'NONE')
  },
  activeLoans: (_, getters) => {
    return getters.list('loan').filter(loan => loan.loanStatus === ACCOUNT_STATUS.ACTIVE)
  },
  getById: (_, getters) => (type, id) => {
    return getters.list(type).find(account => {
      return account.id === parseInt(id)
    })
  },
  getByIdFromDictionary: (state) => (id) => {
    return state.accountsDictionary.find(account => account.id === parseInt(id))
  },
  getByAccountNumber: (state) => (accountNumber) => {
    return state.accountsDictionary.find(account => {
      return account.accountNumber === accountNumber
    })
  },
  availableCurrencies : (_, getters) => {
    return Object.keys(getters.totalAvailableBalanceByCurrency)
  },
  getAccountsByCurrency: (_, getters) => (currency) => {
    return getters.activeSavings.filter(account => account.currency.code === currency)
  },
  totalAvailableBalanceByCurrency: (_, getters, rootState, rootGetters) => {

    const isReadSavingAccountFullPermission = rootGetters['user/userHasPermissions']([{ code: 'READ_SAVINGSACCOUNT', full: true }])

    const activeSavingAccounts = getters.activeSavings.filter (
      ({accountSubType}) => {
        return isReadSavingAccountFullPermission ? accountSubType !== 'SUBSIDIARY_ACCOUNT' : accountSubType === 'SUBSIDIARY_ACCOUNT'
      }
    )

    const result = {}

    activeSavingAccounts.forEach(account => {
      const { code } = account.currency
      const availableBalance = account.availableBalance

      if (code) {
        result[code] = result[code] ? (result[code] += availableBalance) : availableBalance
      }
    })

    return result
  },
  totalBalanceByCurrency: (_, getters, rootState, rootGetters) => {
    const isReadSavingAccountFullPermission = rootGetters['user/userHasPermissions']([{ code: 'READ_SAVINGSACCOUNT', full: true }])

    const activeSavingAccounts = getters.activeSavings.filter (
      ({accountSubType}) => {
        return isReadSavingAccountFullPermission ? accountSubType !== 'SUBSIDIARY_ACCOUNT' : accountSubType === 'SUBSIDIARY_ACCOUNT'
      }
    )
    const result = {}

    activeSavingAccounts.forEach(account => {
      const { code } = account.currency
      const availableBalance = isReadSavingAccountFullPermission ? account.accountBalance : account.availableBalance

      if (code) {
        result[code] = result[code] ? (result[code] += availableBalance) : availableBalance
      }
    })

    return result
  },
  totalBalanceByCurrencyOfPrimaryAccounts: (_, getters) => {
    const activePrimaryAccounts = getters.activeSavings.filter (
      ({accountSubType}) => accountSubType === 'PRIMARY_ACCOUNT'
    )

    if (!activePrimaryAccounts.length) return undefined

    const result = {}

    activePrimaryAccounts.forEach(account => {
      const { code } = account.currency
      const availableBalance = account.accountBalance

      if (code) {
        result[code] = result[code] ? (result[code] += availableBalance) : availableBalance
      }
    })

    return result
  },
  totalAvailableBalanceByCurrencyOfPrimaryAccounts: (_, getters) => {
    const activePrimaryAccounts = getters.activeSavings.filter (
      ({accountSubType}) => accountSubType === 'PRIMARY_ACCOUNT'
    )

    if (!activePrimaryAccounts.length) return undefined

    const result = {}

    activePrimaryAccounts.forEach(account => {
      const { code } = account.currency
      const availableBalance = account.availableBalance

      if (code) {
        result[code] = result[code] ? (result[code] += availableBalance) : availableBalance
      }
    })

    return result
  }
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  updateSavings (state, updatedData) {
    updatedData.forEach(updatedSavings => {
      const updatedCache = state.savingsAccounts.find((cache, index) => {
        if (cache.id === updatedSavings.id) {
          state.savingsAccounts.splice(index, 1, {
            ...state.savingsAccounts[index],
            client: {...updatedSavings.client},
            accountNumber: updatedSavings.accountNumber,
            avatarUrl: updatedSavings.avatarUrl,
            nickName: updatedSavings.nickName,
            accountSubType: updatedSavings.accountSubType,
            parentAccountId: updatedSavings.parentAccountId,
            accountBalance: updatedSavings.accountBalance,
            savingsProductName: updatedSavings.savingsProductName,
            currency: { ...state.savingsAccounts[index].currency, ...updatedSavings.currency },
            availableBalance: updatedSavings.availableBalance,
            status: updatedSavings.status,
            subStatus: updatedSavings.subStatus
          })
          return true
        }
        return false
      })

      if (!updatedCache) state.savingsAccounts.push(updatedSavings)
    })
  },
  updateSavingsDetails (state, savingsDetails) {
    state.savingsAccounts.find((acc, index) => {
      return (acc.id === savingsDetails.id) ? state.savingsAccounts.splice(index, 1, savingsDetails) : false
    })
  },
  updateLoan (state, loanAccounts) {
    loanAccounts.forEach(updatedLoan => {
      const isCacheUpdated = state.loanAccounts.find((cache, index) => {
        if (cache.id === updatedLoan.id) {
          state.loanAccounts.splice(index, 1, {
            ...state.loanAccounts[index],
            accountNumber: updatedLoan.accountNumber,
            product: { ...state.loanAccounts[index].product, ...updatedLoan.product },
            summary: { ...state.loanAccounts[index].summary, ...updatedLoan.summary },
            productDetail: {
              ...state.loanAccounts[index].productDetail,
              currency: { ...state.loanAccounts[index].productDetail.currency, ...updatedLoan.productDetail.currency }
            },
            loanStatus: updatedLoan.loanStatus
          })
          return true
        }
        return false
      })

      if (!isCacheUpdated) state.loanAccounts.push(updatedLoan)
    })
  },
  updateLoanDetails (state, loanDetails) {
    state.loanAccounts.find((loan, index) => {
      return (loan.id === loanDetails.id) ? state.loanAccounts.splice(index, 1, loanDetails) : false
    })
  },
  updateTotalTx (state, payload) {
    state.savingsAccounts = state.savingsAccounts.map(acc => {
      return {
        ...acc,
        totalTx: payload[`totalTxSavings${acc.id}`]?.total || acc.totalTx || 0
      }
    })
  },
  setSavingProducts (state, payload) {
    state.savingProducts = payload
  },
  setSavingAccountNickName (state, {accountId, nickName}) {
    state.savingsAccounts = state.savingsAccounts.map(account => {
      if (account.id === accountId) return { ...account, nickName }
      return account
    })
  },
  setAccountsDictionary (state, payload) {
    state.accountsDictionary = payload
  }
}

const actions = {
  resetState ({ commit }) {
    commit('resetState')
  },
  async download (_, { reportType, accountId }) {
    try {
      const { data } = await downloadAccountReport(reportType, accountId)
      saveDataAsFile(data, `${reportType}.pdf`)
    } catch (error) {
      Promise.reject(error)
    }
  },
  async getSavingProducts ({state, commit}) {
    if (state.savingProducts.length) return state.savingProducts
    const { SavingsProducts: { select: savingProducts } } = gqlErrorHelper(await getSavingProducts())

    const ssuCanCreateSavingProducts = savingProducts.filter(
      savingProduct => savingProduct.ssuCanCreate
    )

    commit('setSavingProducts', ssuCanCreateSavingProducts)
    return state.savingProducts
  },
  async updateAccountNickname ({ commit }, { accountId, nickName }) {
    const {data} = await updateAccountNickname(accountId, nickName)
    if (data.errors) throw data.errors
    else commit('setSavingAccountNickName', { accountId, nickName })
  },
  async createClientAccount({ dispatch }, payload) {
    gqlErrorHelper(await createClientAccount(payload))
    
    dispatch('refreshAccountList')
  },
  async refreshAccountList({ commit }) {
    const response = await getAccountListData()
    commit('updateSavings', response.data.data.getSavingsAccounts)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
