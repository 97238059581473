import { withAuth } from '@/api/request.js'

const request = withAuth()

const getRecipientCurrencyTemplate = () => {
  return request({
    data: {
      query: `
        {
          getCurrencyTemplates {
            currencyCode
            currencyName
            fields {
                fieldName
                fieldRegex
                fieldCode
                validationErrorMessage
            }
          }
        }
      `
    }
  })
}

const getExchangeRate = (payload) => {
  return request({
    data: {
      query: `
        {
          getFetchFxRates(sourceCurrency: "${payload.currency}", destinationCurrency: "${payload.destinationCurrency}") {
            sourceCurrency
            destinationCurrency
            baseFxRate
            fxRate
            lockExpiryAt
            margin
            rateSignature
        }
        }
      `
    }
  })
}

const getLockFxRates = (payload) => {
  return request({
    data: {
      query: `
        mutation{
          lockFxRates(
            lockFxRateInput: {
              sourceCurrency: "${payload?.sourceCurrency}"
              destinationCurrency: "${payload?.destinationCurrency}"
              fxRate: ${payload.fxRate}
              baseFxRate: ${payload.baseFxRate}
              margin: ${payload.margin}
              rateSignature: "${payload.rateSignature}"
              lockExpiryAt: "${payload.lockExpiryAt}"
            }
            ) {
                fxHoldId
                holdExpiryAt
                fxRate
            }
        }
      `
    }
  })
}


const getChargeFee = (payload) => {
  return request({
    data: {
      query :`
        {
          getCharges(
            input: {
              savingsAccountId: ${payload.savingsAccountId}
              paymentRail: FXPAY
              paymentDirection: OUT
              type: CREDIT
              currencyCode: "${payload.currency}"
            }
          ) {
              amount
              currencyCode
          }
        }
      `
    }
  })
}

export {
  getRecipientCurrencyTemplate,
  getChargeFee,
  getExchangeRate,
  getLockFxRates
}
