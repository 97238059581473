import { getRecipientCurrencyTemplate } from '@/api/views/recipients/recipientCurrency';

const getDefaultState = () => {
  return {
    currencyTemplates: []
  }
}

const state = getDefaultState()

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  getCurrencyTemplates (state, payload) {
    state.currencyTemplates = payload
  },
}

const actions = {
  resetState ({ commit }) {
    commit('resetState')
  },
  async getCurrencyTemplates ({commit}) {
    const { data: { data: { getCurrencyTemplates } } } = await getRecipientCurrencyTemplate()
    commit('getCurrencyTemplates', getCurrencyTemplates)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
