import colors from 'vuetify/es5/util/colors.js'

const dark = {
  primary: '#4683f8',
  secondary: colors.teal.base,
  tertiary: colors.red.lighten5,
  quinary: colors.grey.darken1,
  accent: colors.pink.base,
  success: '#A6DE7A',
  error: colors.pink.lighten1,
  chipback: '#188e547',
  quaternary: '#515151', // inside main component elem enclosure BG
  surface2: '#1E2021',
  background: '#111314',
  background2: '#161819',
  surface: '#1B1D1F', // main components BG
  fade: '#26292B',
  alert: '#FF8D8D',
  avatar: '#27292A',
  close: '#737980',
  statusActive: '#5AFF5A',
  statusPending: '#FFFF3B',
  statusInactive: '#FE6565'
}

const light = {
  primary: '#2e4a9e',
  secondary: colors.teal.base,
  tertiary: colors.red.lighten5,
  quaternary: colors.grey.lighten3,
  quinary: colors.grey.lighten2,
  accent: '#1d1a27',
  success: '#4CAF50',
  error: colors.pink.darken3,
  background: '#fafafa',
  background2: '#fafafa',
  surface: '#ffffff',
  surface2: '#f2f2f2',
  chipback: '#1e88e547',
  fade: colors.grey.lighten4,
  alert: '#FF8D8D',
  avatar: colors.grey.lighten3,
  close: '#737980',
  statusActive: '#5AFF5A',
  statusPending: '#FFFF3B',
  statusInactive: '#FE6565'
}

export {
  dark,
  light
}
