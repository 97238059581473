import Vue from 'vue'
import { getAppClient } from '@/utils/clientSpecificHelpers'

Vue.prototype.$clientT = function (message) {
  const client = getAppClient()
  return this.$te(`clientSpecificTranslations.${client}.${message}`)
    ? this.$t(`clientSpecificTranslations.${client}.${message}`)
    : this.$t(message)
}

Vue.prototype.$clientTE = function (message) {
  const client = getAppClient()
  return this.$te(`clientSpecificTranslations.${client}.${message}`)
}
