// get the config from the backend
// list with the enabled components
const features = {
  cheqly: [
    'login',
    'otp',
    'dashboard',
    'dashboard:cardHolderInfo',
    'accounts',
    'accountDetails:accountStatement',
    'accountDetails:bankLetter',
    'accountDetails:achAndWireDetails',
    'currentAccount',
    'loanAccount',
    'newTransfer',
    'transactions',
    // 'communications',
    'settings',
    'recipients',
    'recipientDetails',
    'registration',
    'requestResetPassword',
    'cards',
    'cardLimits',
    'forgotPassword',
    'clients',
    'clientDetails',
    'clientCreate',
    'clientBulkUpload',
    // 'users',
    // 'support',
    'payment-types:internal,swift,wire,ach',
    'bottomNavigations',
    'termsConditions',
    'selectTransfer',
    'internalTransfer',
    'achTransfer',
    'wireTransfer',
    'swiftTransfer',
    'fxPayTransfer',
    'accountCreate',
    'selectRecipientTransferType',
    'internalRecipient',
    'achRecipient',
    'wireRecipient',
    'swiftRecipient',
    'resetPassword',
  ],
  qorbis: [
    'login',
    'otp',
    'dashboard',
    'dashboard:cardHolderInfo',
    'accounts',
    'accountDetails:accountStatement',
    'accountDetails:bankLetter',
    'accountDetails:achAndWireDetails',
    'currentAccount',
    'loanAccount',
    'newTransfer',
    'transactions',
    // 'communications',
    'settings',
    'recipients',
    'recipientDetails',
    'employees',
    'employee',
    'registration',
    'requestResetPassword',
    // 'cards',
    'cardLimits',
    'forgotPassword',
    'clients',
    'clientDetails',
    'clientCreate',
    'clientBulkUpload',
    // 'users',
    // 'support',
    'payment-types:internal,swift,wire,ach',
    'bottomNavigations',
    'termsConditions',
    'selectTransfer',
    'internalTransfer',
    'achTransfer',
    'wireTransfer',
    'swiftTransfer',
    'allocateTransfer',
    'accountCreate',
    'selectRecipientTransferType',
    'internalRecipient',
    'achRecipient',
    'wireRecipient',
    'swiftRecipient',
    'resetPassword',
  ],
  prime: [
    'login',
    'otp',
    'dashboard',
    // 'dashboard:cardHolderInfo',
    'accounts',
    'accountDetails:accountStatement',
    // 'accountDetails:bankLetter',
    'accountDetails:achAndWireDetails',
    'currentAccount',
    'loanAccount',
    'newTransfer',
    'transactions',
    'transactionsDetails',
    // 'communications',
    'settings',
    // 'recipients',
    // 'recipientDetails',
    // 'registration',
    'requestResetPassword',
    // 'cards',
    'forgotPassword',
    // 'users',
    // 'support'
    'payment-types:swift,wire',
    'termsConditions',
    'selectTransfer',
    'internalTransfer',
    'achTransfer',
    'wireTransfer',
    'swiftTransfer',
    'accountCreate',
    'selectRecipientTransferType',
    'internalRecipient',
    'achRecipient',
    'wireRecipient',
    'swiftRecipient',
    'resetPassword',
  ],
  default: [
    'login',
    'otp',
    'dashboard',
    'dashboard:cardHolderInfo',
    'accounts',
    'accountDetails:accountStatement',
    'accountDetails:bankLetter',
    'accountDetails:achAndWireDetails',
    'currentAccount',
    'loanAccount',
    'newTransfer',
    'transactions',
    'transactionsDetails',
    // 'communications',
    'settings',
    'recipients',
    'recipientDetails',
    'employees',
    'employee',
    'registration',
    'requestResetPassword',
    'cards',
    'forgotPassword',
    'clients',
    'clientDetails',
    'clientCreate',
    'clientBulkUpload',
    // 'users',
    'cardLimits',
    // 'support',
    'payment-types:internal,swift,wire,ach',
    'bottomNavigations',
    'termsConditions',
    'selectTransfer',
    'internalTransfer',
    'achTransfer',
    'wireTransfer',
    'swiftTransfer',
    'allocateTransfer',
    'fxPayTransfer',
    'accountCreate',
    'selectRecipientTransferType',
    'internalRecipient',
    'achRecipient',
    'wireRecipient',
    'swiftRecipient',
    'resetPassword',
  ]
}

const client = process.env.VUE_APP_CLIENT

const enabled = (client = '') => {
  const clientFeatures = !features[client] ? features.default : features[client]

  const externalTransferFeatureFlags = ['achTransfer', 'wireTransfer', 'fxPayTransfer', 'swiftTransfer']
  const isExternalTransferEnabled = externalTransferFeatureFlags.some(
    (externalTransferFeatureFlag) => clientFeatures.includes(externalTransferFeatureFlag)
  )

  if (isExternalTransferEnabled) {
    clientFeatures.push('selectExternalTransfer')
  }

  return clientFeatures
}

export default enabled(client)
