import { withAuth } from '@/api/request.js'

const request = withAuth()

const clientDetails = ({ clientId }) => {
  const checkClient = ['mbanq-stage', 'qorbis-stage'].includes(process.env.VUE_APP_CLIENT_NAME)
  const cardAccount = checkClient ? 'subsidiaryAccount' : 'account';
  return request({
    data: {
      query: `
        {
         getClientDetails(clientId: ${clientId}) {
           id
           dateOfBirth
           emailAddress
           mobileNo
           mobileCountryCode
           avatarUrl
           clientTypes {
			id
			label
			description
			identifier
		    position
		   }
           accounts {
            id
            accountNo
            parentAccountId
            savingsProductName
            savingsProductId
            availableBalance
            mobileNo
            nickName
            designation
            remainingAllocatedFunds
            savingsAmountOnHold
            currency
            client {
              id
            }
           }
		   addresses {
			address {
			  id
			  city
			  addressLine1
			  addressLine2
			  addressLine3
			  country {
			    id
			    label
			    identifier
			    description
			  }
			}
			addressType {
			  id
			  label
			  description
			  identifier
			}
		   }
		   cards {
		    id
		    productName
		    expiresOn
		    network
		    status
		    primaryAccountNumber
		    virtual
				digitalFirst
				account: ${cardAccount} {
					id
					nickName
					accountNo
					parentAccount {
						id
					}
					}
		   }

		   firstName
		   lastName
		   identifiers {
		    id
		    documentKey
		    documentType {
		      id
		      identifier
		      description
		    }
		   }
		   tier {
             id
             name
		   }
		   parentId
		   status
		   submittedOn
		   users {
		    id
		    firstName
		    lastName
		    mobile
		    roles {
		      id
		      name
		    }
		   }
	      }
	    }
      `
    }
  })
}

const getClientTiers = () => {
  return request({
    data: {
      query: `
        {
          getEmployeeTiers {
		    id
		    name
		    description
		    enabled
	      }
	    }
      `
    }
  })
}

const changeClientTier = (clientId, tierId) => {
  return request({
    data: {
      query: `mutation {
        changeClientTier(
          clientId: ${clientId}
          tierId: ${tierId}
        )
      }`
    }
  })
}

const changeClientRole = (clientId, roleId) => {
  return request({
    data: {
      query: `mutation {
        changeClientRole(
          clientId: ${clientId}
          roleId: ${roleId}
        )
      }`
    }
  })
}

export {
  clientDetails,
  getClientTiers,
  changeClientTier,
  changeClientRole
}
