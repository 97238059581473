import { getAppClient } from '@/utils/clientSpecificHelpers'

const generateAccountStatementFilename = (accountNumber) => {
  const clientName = getAppClient()
  const client = clientName.charAt(0).toUpperCase() + clientName.slice(1)
  return `${client}-Account-Statement-${accountNumber}.pdf`
}

export {
  generateAccountStatementFilename
}
