import ls from 'localstorage-slim';

export class SecureLocalStorageService {
  static instance;

  constructor () {
    const clientSecret = process.env.VUE_APP_CLIENT_SECRET || 'SECRET';

    ls.config.storage = sessionStorage;
    ls.config.secret = clientSecret;
    this.storage = ls;
  }

  getItem (key) {
    return this.storage.get(key)
  }

  setItem (key, value) {
    this.storage.set(key, value)
  }

  removeItem (key) {
    this.storage.remove(key)
  }

  clear () {
    this.storage.clear()
  }

  static getInstance() {
    if (!SecureLocalStorageService.instance)
      SecureLocalStorageService.instance = new SecureLocalStorageService();

    return SecureLocalStorageService.instance;
  }
}
