import { withAuth } from '@/api/request.js'

const request = withAuth()

const getCardProducts = () => {
  return request({
    data: {
      query: `
        {
          CardProducts (where: { legalForm: { EQ: 2 } } ) {
            select {
              id
              name
              cardType
              network
              yearExpire
              virtual
              currency {
                code
              }
            }
          }
        }
      `
    }
  })
}

const getCardProductDetail = ({cardProductId, tierId}) => {
  return request({
    data: {
      query: `
        {
          CardProducts(where: { id: { EQ: ${cardProductId} } } ${tierId ? `,tierId: ${tierId}` : '' }) {
            select {
              id
              name
              mergedVelocityRules {
                value
                id
                timePeriod
                controls
                type
                timeUnit
                category
                categoryId
              }
            }
          }

        }
      `
    }
  })
}

const orderEmployeeCard = (payload) => {
  return request({
    data: {
      query: `mutation {
        orderCard(
          input: {
            productId: "${payload.productId}"
            savingsAccountId: "${payload.savingsAccountId}"
            userId: "${payload.userId}"
          }
        ) {
          id
          status
          }
      }`
    }
  })
}

const getEmployeeCards = ({ subAccountId }) => {
  return request({
    data: {
      query: `
        {
          getSubsidiaryAccountCards(subAccountId: ${subAccountId}) {
           id
           network
           productName
           primaryAccountNumber
           status
           expiresOn
           posPaymentEnabled
           onlinePaymentEnabled
           contactlessPaymentEnabled
           atmWithdrawalsEnabled
           internationalPaymentsEnabled
           blockedCountries
           physicalCardActivated
          }
        }
      `
    }
  })
}

const getEmployeeCardVelocityRules = ({ cardId }) => {
  return request({
    data: {
      query: `
        {
          VelocityRules(cardId: ${cardId}) {
            controls
            expiryDate
            timeUnit
            timePeriod
            type
            value
          }
        }
      `
    }
  })
}

export {
  orderEmployeeCard,
  getCardProducts,
  getCardProductDetail,
  getEmployeeCards,
  getEmployeeCardVelocityRules
}
