import { withAuth } from '@/api/request.js'
import { card } from '@/api/fragments/index.js'

const request = withAuth()

const updateOnlinePayment = (cardId, value) => {
  return request({
    data: {
      query: `
        mutation {
          updateCard (
            id: ${cardId}, 
            input: {
              onlinePaymentEnabled: ${value}
            }
          ) {
            id
          }
        }
      `
    }
  })
}

const activatePhysicalCard = (cardId) => {
  return request({
    data: {
      query: `
        mutation {
          activatePhysicalCard(id: ${cardId}) {
            id
            status
          }
        }
      `
    }
  })
}

const updateContactlessPayment = (cardId, value) => {
  return request({
    data: {
      query: `
        mutation {
          updateCard (
            id: ${cardId}, 
            input: {
              contactlessPaymentEnabled: ${value}
            }
          ) {
            id
          }
        }
      `
    }
  })
}

const updateAtmWithdrawals = (cardId, value) => {
  return request({
    data: {
      query: `
        mutation {
          updateCard (
            id: ${cardId}, 
            input: {
              atmWithdrawalsEnabled: ${value}
            }
          ) {
            id
          }
        }
      `
    }
  })
}

const updateInternationalPayments = (cardId, value) => {
  return request({
    data: {
      query: `
        mutation {
          updateCard (
            id: ${cardId}, 
            input: {
              internationalPaymentsEnabled: ${value}
            }
          ) {
            id
          }
        }
      `
    }
  })
}

const updateStatus = (cardId, value) => {
  return request({
    data: {
      query: `
        mutation{
          handleCardEvent(id:${cardId},
          input: {event: ${value}}){
            id
            status
          }
        }
      `
    }
  })
}

const cardsList = () => {
  return request({
    data: {
      query: `
        {
          Cards (where: {status:{NIN:[TERMINATED, REJECTED]}}) {
            select {
              ...card
            }
          }
        }
        ${card}
      `
    }
  })
}

const getPin = (id) => {
  return request({
    data: {
      query: `
        {
          pin:getPan(id: ${id} )
        }
      `
    }
  })
}

const getChangeCardPinUrl = (cardId) => {
  return request({
    data: {
      query: `
        {
          getChangeCardPinUrl(id: ${cardId} )
        }
      `
    }
  })
}

const getPinImg = (id) => {
  return request({
    data: {
      query: `
      {
        getCardPinUrl(id: ${id})
      }
      `
    }
  })
}

const replaceCard = (id, replaceReason) => {
  return request({
    data: {
      query: `
        mutation {
          replaceCard(id: ${id}, input: {replaceReason: ${replaceReason}}) {
            id
            status
          }
        }
        `
    }
  })
}

const updateRegion = (cardId, value) => {
  return request({
    data: {
      query: `
        mutation {
          updateCard (
            id: ${cardId}, 
            input: {
              blockedCountries: [${value.map(i => `"${i}"`).join(',')}]
            }
          ) {
            id
          }
        }
      `
    }
  })
}

const updateCardLimit = (cardId, velocityRules) => {
  return request({
    data: {
      query: `
      mutation {
        updateCardLimits (id: ${cardId}, input: {
          velocityRules: [${velocityRules.filter(rule => rule.value > 0).map(item => `{
            controls: [${item.controls[0]}],
            type: ${item.type},
            timePeriod: ${item.timePeriod},
            timeUnit: ${item.timeUnit.toUpperCase()},
            ${item.expiryDate ? `expiryDate: "${item.expiryDate} 23:59:59",` : ''}
            value: ${item.value}
          }`).join(',')}]
        }) {
          id
        }
      }
      `
    }
  })
}

const getCardImage = cardId => {
  return request({
    data: {
      query: `{getCardImageUrl(id: ${cardId})}`
    }
  })
}

const getCardDetails = cardId => {
  return request({
    data: {
      query: `{
        Card(id: ${cardId}) {
          id
          physicalCardActivated
          internationalPaymentsEnabled
          atmWithdrawalsEnabled
          network
          expiresOn
          primaryAccountNumber
          fulfillmentStatus
          status
          velocityRules {
			id
			controls
			expiryDate
			timeUnit
			timePeriod
			type
			value
		  }
		  product {
			id
			velocityRules {
			  id
			  controls
			  expiryDate
			  timeUnit
			  timePeriod
			  type
			  value
			}
		  }
        }
      }`
    }
  })
}

export {
  cardsList,
  updateOnlinePayment,
  updateContactlessPayment,
  updateStatus,
  getPin,
  getPinImg,
  updateRegion,
  updateCardLimit,
  updateAtmWithdrawals,
  updateInternationalPayments,
  activatePhysicalCard,
  getChangeCardPinUrl,
  getCardImage,
  replaceCard,
  getCardDetails
}
