import { withAuth } from '@/api/request.js'

const request = withAuth()

const createClientAccount = ({ clientId, primaryAccountId, nickName }) => {
  return request({
    data: {
      query: `mutation {
        createClientSubAccount(
            input: { 
                primaryAccountId: ${primaryAccountId}, 
                ${nickName ? `nickName: "${nickName}",` : ''} 
                clientId: ${clientId} 
            }
        ) {
            id
		    accountNo
		    nickName
		    parentAccountId
        }
      }`
    }
  })
}

export {
  createClientAccount
}
