import { withAuth } from '@/api/request.js'
import {
  savingsTransaction,
  savingsTransactionDetails,
  subsidiaryAccountTransactionDetails,
  subsidiaryAccountTransaction,
  cardAuthorizationData,
  debtorCreditorData
} from '@/api/fragments/index.js'
import store from '@/store/index.js'

const request = withAuth()

export default (id) => {

  const isEmployee = store.getters['user/isEmployee']

  if(isEmployee) {
    return request({
      data: {
        query: `
        {
          getSubsidiaryAccountTransaction(id: ${id}) {
            id
            ...subsidiaryAccountTransaction
            ...savingsTransactionDetails
          }
        }
        ${subsidiaryAccountTransaction}
        ${subsidiaryAccountTransactionDetails}
      `
      }
    })
  }

  return request({
    data: {
      query: `
        {
          SavingsAccountTransaction(id: ${id}) {
            id
            ...savingsTransaction
            ...savingsTransactionDetails
          }
        }
        ${savingsTransaction}
        ${savingsTransactionDetails}
      `
    }
  })
}

export const getTransferDocuments = (transferId) => {

  return request({
    data: {
      query: `
      {
        Medias(where: { entityId: { EQ: ${transferId} }, entityType: { EQ: "transfer" } }) {
          select {
            id
            name
            entityType
            entityId
            filename
          }
        }
      }
      `
    }
  })
}

export const getGeneralTransactionDetailsData = (id, type) => {
  return request({
    data: {
      query: `
      {
        getAccountsHistoryDetails(type: ${type}, id: ${id}) {
          typeOf
          subsidiaryAccountId
          transactionType
          transactionSubType
          cardAuthorization {
            ...cardAuthorizationData
		  }	
		  authorizeTransaction {
		    id
		    amount
		    typeOf
		    active
		    createdAt
		    account {
		      id
		      accountNo
		      currency {
		        id
		        code
		        displayLabel
		        displaySymbol
		        name
		      }
		    }
		    cardAuthorization {
		      ...cardAuthorizationData
		    }
		    transaction {
		      paymentDetail {
		        id
		        reference
		        paymentType {
		          id
		          name
		        }
		        creditor {
		          ...debtorCreditorData
		        }
		        debtor {
		          ...debtorCreditorData
		        }
		        cardAuthorizationData {
                  ...cardAuthorizationData
                }
                merchant
              }
            }
            transfer {
              reference
              type
              paymentType {
                id
                name
              }
            }
          }
          savingsAccountTransaction {
            id
            amount
            bookingDate
            createdAt
            runningBalance
            paymentDetailData {
              id
              reference
              paymentType {
                id
                name
              }
              creditor {
                ...debtorCreditorData
              }
              debtor {
                ...debtorCreditorData
              }
              cardAuthorizationData {
                ...cardAuthorizationData
              }
              merchant
            }
          }
        }
      }
      ${cardAuthorizationData}
      ${debtorCreditorData}
      `
    }
  })
}

export const getTransferId = (transactionId) => {
  return request({
    data: {
      query: `
      {
        Transfers(where: {
          debtorSavingsAccountTransaction: {
            id: {
              EQ: ${transactionId}
            }
          }
        }) {
          select {
            id
          }
        }
      }`
    }
  })
}
