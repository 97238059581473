import { withAuth } from '@/api/request.js'

const request = withAuth()

export default ({ username }) => {
  return request({
    data: {
      query: `
        {
          AppUsers(where: { OR: [{username: {EQ: "${username}"}}, {mobile: {EQ: "${username}"}}]}) {
            select {
              id
              username
              lastName
              firstName
              email
              mobile
              roles {
                rolePermissions {
                  hasFullAccess
                  permission {
                    code
                  }
                }
              }
            }
          }
          Clients {
            select {
              id
              displayName
              legalForm
              avatarUrl
              status
              parent {
                id
                displayName
              }
            }
          }
        }
      `
    }
  })
}
