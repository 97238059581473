import router from '@/router/index.js'
import { isNavigationFailure, NavigationFailureType } from 'vue-router';
import {
  requestResetPassword,
  resetPassword,
  changePassword,
  getUserDetails,
  getAuthenticationDetail,
  getClientDetails,
  acceptTermsConditons as acceptTermsAndConditionsAPI,
  getFeatureConfiguration,
  resetPasswordWithConfiguration
} from '@/api/user.js'
import {
  auth,
  validateOTP,
  requestOTP,
  enableTOTP,
  confirmTOTP,
  disableTOTP
} from '@/api/auth.js'
import { clearLocalStorage, permissionsArePresent } from '@/utils/helpers.js'
import EventBus from '@/utils/eventBus.js'
import i18n from '@/plugins/i18n'
import { restApi } from '@/api/request'
import { SecureLocalStorageService } from '@/services/SecureLocalStorageService'
import {LEGAL_FORM_TYPE} from '@/utils/dictionaries/legalFormType'

const secureLocalStorage = SecureLocalStorageService.getInstance()

const getDefaultState = () => {
  return {
    locale: 'en',
    dateFormat: 'yyyy-MM-dd',
    authenticated: false,
    required2FA: false,
    isPasswordExpired: false,
    isRequireAcceptTermsConditions: false,
    appUser: {},
    username: '',
    officeId: null,
    details: {
      getAuthenticationDetail: {
        mfaDeliveryMethods: []
      }
    }
  }
}

const state = getDefaultState()

const getters = {
  get: state => state,
  id: state => state.appUser.id,
  fullName: state => state.client?.displayName,
  userFullName: state => `${state.appUser.firstName} ${state.appUser.lastName}`,
  clientEntity: state => state.clientEntity,
  organizationName: state => state.clientEntity?.displayName || '',
  isEmployee: (state) => !!state.client?.parent?.id,
  email: state => state.appUser.email,
  user: state => state.appUser,
  isTotpEnabled: state => state.details?.getAuthenticationDetail?.mfaDeliveryMethods?.find(method => method === 'TOTP'),
  permissions: state => state.appUser.roles?.map(role => role.rolePermissions.map(permission => {
    return { code: permission.permission.code, full: permission.hasFullAccess }
  })).flat(),
  userHasPermissions: (_, getters) => (permissions) => permissionsArePresent(permissions, getters.permissions)
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  update (state, payload) {
    Object.assign(state, payload)
  },
  updateDetails (state, payload) {
    Object.assign(state, {
      ...state,
      details: {
        ...state.details,
        ...payload
      }
    })
  }
}

const actions = {
  resetState ({ commit }) {
    commit('resetState')
  },
  update (context, payload) {
    context.commit('update', payload)
  },
  async login ({ commit, dispatch, state }, credentials) {
    await auth(credentials)
    commit('update', { username: credentials.username })

    const isRequiredResetPassword = secureLocalStorage.getItem('required_password_reset')
    const isRequireAcceptTermsConditions = secureLocalStorage.getItem('required_terms_and_conditions_check')
    const isByPassResetPasswordEmail = secureLocalStorage.getItem('should_bypass_reset_password_email')

    if (isRequiredResetPassword) {
      if (!isByPassResetPasswordEmail) return router.push('/request-reset-password')
      const { data: { data: { requestPasswordResetTokenFromPlatform: token } } } = await resetPasswordWithConfiguration()
      router.push({ name: 'forgotPassword', params: { token } })
    } else if (isRequireAcceptTermsConditions) {
      commit('update', { isRequireAcceptTermsConditions: true })
      return router.push('/terms-conditions')
    } else {
      await dispatch('updateUserDetails')
      await dispatch('checkSelfServiceUser');
      await dispatch('checkExpiredPassword');
      await dispatch('checkMFA')
      if(state.details.getAuthenticationDetail.isMFARequired && !secureLocalStorage.ls['2FA-Token']) return
      await dispatch('updateClient')
    }
  },
  async updateClient ({commit, dispatch}) {
    const { data: { data: clientDetails } } = await getClientDetails()
    commit('updateDetails', clientDetails)

    await dispatch('checkAttachClient')
    if(await dispatch('checkUnverifiedEntityClient')) {
      return await router.push('/registration')
    }

    const { data: { data: { featureConfiguration: { isDelinkUserFromEntity } } } } = await getFeatureConfiguration()

    if (isDelinkUserFromEntity) {
      await dispatch('checkActiveClientPerson')
    } else {
      await dispatch('checkActiveClient')
    }

    await dispatch('application/toggleLoadingScreen', '', { root: true })
    commit('update', { authenticated: true })
  },
  async checkAttachClient ({state}) {
    if (!state.details.Clients.select?.length) return EventBus.$emit('notify-with-message', i18n.t('views.error.noAttachedClient'), { status: 'error'})
  },
  async checkActiveClientPerson ({state}) {
    const clientPerson = state.details.Clients?.select?.find(client => client.legalForm === LEGAL_FORM_TYPE.PERSONAL)

    if(Number(clientPerson?.status) !== 300) {
      EventBus.$emit('notify-with-message', i18n.t('views.error.clientIsNotActivated'), { flushQueue: true, status: 'error' })
      return router.push('/login')
    }
  },
  async checkActiveClient ({state}) {
    const client = state.details.Clients?.select?.[0]

    if(Number(client?.status) !== 300) {
      EventBus.$emit('notify-with-message', i18n.t('views.error.clientIsNotActivated'), { flushQueue: true, status: 'error' })
      return router.push('/login')
    }
  },
  async acceptTermsConditons ({ commit, dispatch }) {
    try {
      await acceptTermsAndConditionsAPI()
    } catch {
      EventBus.$emit('notify-with-message', 'Unable to accept term and condition', { status: 'error'})
    }

    commit('update', { isRequireAcceptTermsConditions: false })
    await dispatch('checkExpiredPassword')
    await dispatch('application/toggleLoadingScreen', '', { root: true })
    commit('update', { authenticated: true })
  },
  async updateUserDetails({ commit }) {
    const { data: { data: userDetails }  } = await getUserDetails();
    commit('updateDetails', userDetails);
  },
  async checkSelfServiceUser ({ state }) {
    const { isSelfServiceUser } = state.details.getAuthenticationDetail
    if (!isSelfServiceUser) throw new Error('noPermissions')
  },
  async checkUnverifiedEntityClient ({ state }) {
    const entityClient = state.details.Clients.select.find(client => client.legalForm === LEGAL_FORM_TYPE.ENTITY)

    if(Number(entityClient?.status) !== 100) return false // 100 = Unverified client
    secureLocalStorage.setItem('clientId', entityClient.id)
    return true
  },
  async checkMFA ({ state, commit, getters }) {
    const { isMFARequired } = state.details.getAuthenticationDetail

    if (!isMFARequired) return
    if (!getters.isTotpEnabled) requestOTP()

    commit('update', { required2FA: true })

    await router.push({ name: 'otp' })

    return true
  },
  async checkExpiredPassword ({ state, commit }) {
    const { isPasswordExpired } = state.details.getAuthenticationDetail

    if (!isPasswordExpired) return

    commit('update', { isPasswordExpired: true })
    return router.push('/reset-password')
  },
  async logout ({ dispatch }) {
    const invalidateToken = restApi({
      baseURL: `${process.env.VUE_APP_API_URL}/oauth/invalidate`,
      method: 'post'
    })

    try {
      await invalidateToken()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Invalid token will be logging off.')
    }

    await dispatch('application/resetState', '', { root: true })
    if (typeof window.zE?.hide === 'function') window.zE.hide()
    clearLocalStorage()
    await router.push({ name: 'login' }).catch((error)=> {
      if (isNavigationFailure(error, NavigationFailureType.duplicated)) return;
      throw Error
    })
  },
  async submitOtp ({ state, commit, dispatch }, otp) {
    const twoFA = await validateOTP(otp)
    if (twoFA.data.errors) {
      throw twoFA.data.errors
    }
    secureLocalStorage.setItem('2FA-Token', twoFA.data.data.requestAccessTokenFromOTP.token)
    const isPasswordExpired = state.details.getAuthenticationDetail.isPasswordExpired

    if (isPasswordExpired) {
      commit('update', { isPasswordExpired: true })
      return router.push('/reset-password')
    }
    await dispatch('updateClient')
  },
  async requestResetPassword (context, identifier) {
    const result = await requestResetPassword(identifier)
    if (result.data.errors && result.data.errors.length > 0) {
      throw result.data.errors[0]
    }
    return result
  },
  async resetPassword (context, payload) {
    const result = await resetPassword(payload)
    if (result.data.errors && result.data.errors.length > 0) {
      throw result.data.errors[0]
    }
    return result
  },
  async submitPasswordChanges ({ state }, password) {
    const result = await changePassword({ identifier: state.username, ...password })
    if (result.data.errors && result.data.errors.length > 0) {
      throw result.data.errors[0]
    }
    return result
  },
  async updateAuthenticationDetail ({ commit }) {
    const newDetail = (await getAuthenticationDetail()).data.data
    commit('updateDetails', newDetail)
  },
  async enableTOTP () {
    return enableTOTP()
  },
  async disableTOTP ({ state, dispatch }, payload) {
    const dataTOTP = await disableTOTP(payload)

    if (dataTOTP.data.data === null) {
      throw new Error(dataTOTP.data.errors[0].message)
    }

    if (!state.details.getAuthenticationDetail.isMFARequired) {
      secureLocalStorage.removeItem('2FA-Token')
    }

    await dispatch('updateAuthenticationDetail')
  },
  async confirmEnableTOTP (_, payload) {
    const dataTOTP = await confirmTOTP(payload)

    if (!dataTOTP.data.data?.confirmTOTP) {
      throw new Error(dataTOTP.data.errors[0].message)
    }

    const { mfaToken, success, recoveryCodes } = dataTOTP.data.data.confirmTOTP

    if (dataTOTP.data.data.confirmTOTP.mfaToken) {
      secureLocalStorage.setItem('2FA-Token', mfaToken.token)
    }

    return { success, recoveryCodes }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
