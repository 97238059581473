import { getRolesAvailableForUser, getSSURoles, getUsersList } from '@/api/views/users'

const getDefaultState = () => {
  return {
    users: [],
    roles: [],
    availableRoles: []
  }
}

const state = getDefaultState()

const getters = {
  usersList: state => state.users?.select || [],
  userById: (state, getters) => id => getters.usersList.find(item => item.id === id)
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  update (state, payload) {
    Object.assign(state, payload)
  }
}

const actions = {
  resetState ({ commit }) {
    commit('resetState')
  },
  async roles ({ commit }) {
    const roles = await getSSURoles()
    commit('update', { roles: roles?.data?.data?.getSSURoles })
  },
  async users ({ commit }, params) {
    const users = await getUsersList(params)
    commit('update', { users: users?.data?.data?.AppUsers })
  },
  async availableRoles ({ commit }) {
    const rolesData = await getRolesAvailableForUser()
    let availableRoles = rolesData?.data?.data?.getUserTemplateForClient?.availableRoles || []
    availableRoles = availableRoles.filter(role => !role.disabled)
    commit('update', { availableRoles })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
